import { useEffect } from 'react'

import { useReactiveVar } from '@apollo/client'
import { Button, Subheading1 } from '@lumoslabs/lumosity-storybook'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { AppDimensions } from '~/constants'
import { EventClickNamesValues, EventScreenNameValues } from '~/events/eventTypes'
import useTrackClick from '~/events/trackers/useTrackClick'
import { showSideNavVar } from '~/graphql/reactive-vars/uiVar'
import useLocalDate from '~/hooks/useLocalDate'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import AvatarCircleIcon from '~/images/icons/SystemOutlined/AvatarCircle.svg'
import Bulb from '~/images/icons/SystemOutlined/Bulb.svg'
import GameListIcon from '~/images/icons/SystemOutlined/GameList.svg'
import MoonIcon from '~/images/icons/SystemOutlined/Moon.svg'
import ShiftRightIcon from '~/images/icons/SystemOutlined/ShiftRight.svg'
import SunIcon from '~/images/icons/SystemOutlined/Sun.svg'
import { breakpoints } from '~/styles/layout'

/**
 * the properties click_name and destination are used for tracking events
 */
type SideNavNameType = 'today' | 'games' | 'me' | 'insights'
type SideNavBarButtonsType = Record<
  SideNavNameType,
  {
    title: SideNavNameType
    route: string
    clickName: EventClickNamesValues
    destination: EventScreenNameValues
  }
>

const SideNavBarButtons: SideNavBarButtonsType = {
  today: { title: 'today', route: '/', clickName: 'home', destination: 'home' },
  games: { title: 'games', route: '/games', clickName: 'games', destination: 'game' },
  me: { title: 'me', route: '/stats', clickName: 'stats', destination: 'stats' },
  insights: {
    title: 'insights',
    route: '/insights',
    clickName: 'insights',
    destination: 'insights',
  },
} as const

interface ShowSideNavProps {
  $showSideNav: boolean
  $tabActive?: boolean
}

const SideNav = (): JSX.Element => {
  const { hour: localHour } = useLocalDate()
  const t = useTranslationForNamespace('common')
  const sideNavOpen = useReactiveVar(showSideNavVar)

  const toggleSideNav = () => {
    showSideNavVar(!showSideNavVar())
  }

  const { trackCta } = useTrackClick()

  const router = useRouter()
  const tabActive = (route: string) => router.pathname === route

  useEffect(() => {
    const resizeHandler = () => {
      const hideSideNav = document.body.clientWidth < breakpoints.desktop
      if (hideSideNav) {
        showSideNavVar(false)
      }
    }
    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  const handleClick = (text: string, clickName: EventClickNamesValues, destination: EventScreenNameValues) => {
    trackCta({
      click_name: clickName,
      destination: destination,
      text: text,
      type: 'link',
    })
  }

  const sideNavButtons = [
    SideNavBarButtons.today,
    SideNavBarButtons.games,
    SideNavBarButtons.me,
    SideNavBarButtons.insights,
  ]

  return (
    <Container $showSideNav={sideNavOpen}>
      <NavButtonsContainer>
        {sideNavButtons.map((sideNavButton) => {
          const { title, route, clickName, destination } = sideNavButton
          const text = t(`menu.${title}`)

          return (
            <MenuLinkContainer
              href={route}
              key={`menu.${title}`}
              $showSideNav={sideNavOpen}
              $tabActive={tabActive(route)}
              onClick={() => handleClick(text, clickName, destination)}
            >
              <MenuIcons $showSideNav={sideNavOpen}>
                <MenuSvgIcon hour={localHour} text={text} title={title} />
              </MenuIcons>
              <Text $showSideNav={sideNavOpen}>{text}</Text>
            </MenuLinkContainer>
          )
        })}
      </NavButtonsContainer>
      <SideNavControlButton onClick={toggleSideNav} kind='clean' justifyContent='start' $showSideNav={sideNavOpen}>
        <OpenCloseIcon title='Toggle Menu' $showSideNav={sideNavOpen} />
      </SideNavControlButton>
    </Container>
  )
}
export default dynamic(() => Promise.resolve(SideNav), { ssr: false })

const MenuSvgIcon = ({ hour, text, title }: { hour: number; text: string; title: string }) => {
  const commonIconProp = { title: text, fontSize: '32px', width: '32px' }
  if (title === 'games') {
    return <GameListIcon {...commonIconProp} />
  }
  if (title === 'me') {
    return <AvatarCircleIcon {...commonIconProp} />
  }
  if (title === 'today') {
    if (hour < 6 || hour >= 17) {
      return <MoonIcon {...commonIconProp} />
    } else {
      return <SunIcon {...commonIconProp} />
    }
  }
  if (title === 'insights') {
    return <Bulb {...commonIconProp} />
  }

  return null
}

const Container = styled.div<ShowSideNavProps>`
  /* vertical nav */
  overflow-x: hidden; // border was getting clipped because of overflow

  width: ${({ $showSideNav }) => ($showSideNav ? AppDimensions.sideBarOpenWidth : AppDimensions.sideBarCloseWidth)};
  transition: width 200ms cubic-bezier(0.73, -0.12, 0.38, 1.16);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colorTokens.border['border-subdued']};
  background: ${({ theme }) => theme.colorTokens.surface['surface-default']};

  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* bottom floating nav */
    justify-content: center;
    height: 72px;
    width: 100%;
    overflow: hidden;
  }
`

const NavButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* Button row within floating nav */
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
  }
`

const MenuLinkContainer = styled(Link)<ShowSideNavProps>`
  padding-left: ${({ $showSideNav }) => ($showSideNav ? '40px' : '0px')};
  color: ${({ $tabActive, theme }) =>
    $tabActive ? theme.colorTokens.text['text-interactive'] : theme.colorTokens.text['text-disabled']};
  cursor: pointer;
  height: 72px;
  display: flex;
  flex-direction: ${({ $showSideNav }) => ($showSideNav ? 'row' : 'column')};
  justify-content: ${({ $showSideNav }) => ($showSideNav ? 'flex-start;' : 'center')};
  align-items: center;
  position: relative;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colorTokens.border['border-subdued']};
  :hover {
    color: ${({ $tabActive, theme }) =>
      $tabActive ? theme.colorTokens.text['text-interactive'] : theme.colorTokens.text['text-subdued']};
    background-color: ${({ theme }) => theme.colorTokens.background['background-subdued']};
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    border: 0px;
    padding: 16px 0px;
    /* Center icon and text for floating nav*/
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > span {
      /* reset padding on badge icon */
      padding: 0;
    }
  }
`

const Text = styled(Subheading1)<ShowSideNavProps>`
  text-transform: capitalize;
  font-weight: 500;
  margin-left: ${({ $showSideNav }) => ($showSideNav ? '12px' : '0px')};
  transition: margin 200ms cubic-bezier(0.73, -0.12, 0.38, 1.16);
  display: ${({ $showSideNav }) => ($showSideNav ? 'inline' : 'none')}; // hide text if nav is closed on large screen
  font-size: ${({ $showSideNav }) => ($showSideNav ? undefined : '16px')};
  line-height: ${({ $showSideNav }) => ($showSideNav ? undefined : '24px')};
  ${({ theme }) => theme.mediaQuery.maxWidth.tabletLarge} {
    display: inline-block; // show text on small screen
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* always display labels on small screen */
    display: inline-block;
    font-size: 15px;
    line-height: 21px;
  }
`

const SideNavControlButton = styled(Button)<ShowSideNavProps>`
  :hover {
    svg {
      background: ${({ theme }) => theme.colorTokens.background['background-subdued']};
    }
  }
  width: fit-content;
  padding: 24px;
  padding-left: ${({ $showSideNav }) => ($showSideNav ? '32px' : '24x')};
  ${({ theme }) => theme.mediaQuery.maxWidth.tabletLarge} {
    /* On smallest screen size, no toggle button needed */
    /* b/c nav is always visible */
    display: none;
  }
`

const MenuIcons = styled.span<ShowSideNavProps>`
  display: flex;
  font-size: 32px;
  width: 32px;
  transition: margin 200ms cubic-bezier(0.73, -0.12, 0.38, 1.16);
`

const OpenCloseIcon = styled(ShiftRightIcon)<ShowSideNavProps>`
  cursor: pointer;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  transform: ${({ $showSideNav }) => ($showSideNav ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 200ms cubic-bezier(0.73, -0.12, 0.38, 1.16);
  color: ${({ theme }) => theme.colorTokens.icon['icon-subdued']};
`
